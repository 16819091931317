var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"u-color-grey-base u-position-relative"},[_c('div',{staticClass:"u-display-flex u-flex-justify-content-flex-end u-flex-direction-column",class:_vm.cellAlignment},[_c('div',{staticClass:"u-display-flex u-flex-justify-content-flex-end",class:{ 'u-color-grey-x-light': _vm.columnData === 'NA' },attrs:{"title":_vm.getToolTipText(_vm.row[_vm.field])}},[_vm._v(" "+_vm._s(_vm.columnData)+" ")]),(_vm.modeSelection === 'realtime' && !_vm.enablePVPOnly)?_c('div',[_c('div',{staticClass:"u-display-flex u-spacing-pt-xs u-flex-align-items-center u-flex-justify-content-flex-end u-font-size-7 u-font-weight-regular",class:{
          'u-color-blue-base': _vm.streamData !== 'NA',
          'u-color-grey-lighter': _vm.streamData === 'NA'
        }},[_c('streamMetricLabel',{attrs:{"icon-class":"rb-icon--small","stream-metric-data":_vm.streamData,"parent-class":"","show-label":false,"delay":[700, 0]}}),_c('div',{staticClass:"u-spacing-pl-xxs",class:{
            'u-color-blue-base': _vm.streamData !== 'NA',
            'u-color-grey-lighter': _vm.streamData === 'NA'
          }},[_vm._v(" "+_vm._s(_vm.streamData || 'NA')+" ")])],1)]):(
        _vm.modeSelection === 'pvp' ||
        (_vm.enablePVPOnly && _vm.modeSelection !== 'realtime')
      )?_c('div',{staticClass:"u-display-flex u-flex-direction-row-reverse u-width-100"},[_c('metric',{staticClass:"u-spacing-pt-xs u-display-inline-flex",attrs:{"size":"s","config":_vm.pvpConfig,"data":_vm.pvpData}})],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }