<template>
  <div class="u-color-grey-base u-position-relative">
    <div
      class="u-display-flex u-flex-justify-content-flex-end u-flex-direction-column"
      :class="cellAlignment"
    >
      <div
        :title="getToolTipText(row[field])"
        :class="{ 'u-color-grey-x-light': columnData === 'NA' }"
        class="u-display-flex u-flex-justify-content-flex-end"
      >
        {{ columnData }}
      </div>
      <div v-if="modeSelection === 'realtime' && !enablePVPOnly">
        <div
          class="u-display-flex u-spacing-pt-xs u-flex-align-items-center u-flex-justify-content-flex-end u-font-size-7 u-font-weight-regular"
          :class="{
            'u-color-blue-base': streamData !== 'NA',
            'u-color-grey-lighter': streamData === 'NA'
          }"
        >
          <streamMetricLabel
            icon-class="rb-icon--small"
            :stream-metric-data="streamData"
            parent-class=""
            :show-label="false"
            :delay="[700, 0]"
          />
          <div
            class="u-spacing-pl-xxs"
            :class="{
              'u-color-blue-base': streamData !== 'NA',
              'u-color-grey-lighter': streamData === 'NA'
            }"
          >
            {{ streamData || 'NA' }}
          </div>
        </div>
      </div>
      <div
        v-else-if="
          modeSelection === 'pvp' ||
          (enablePVPOnly && modeSelection !== 'realtime')
        "
        class="u-display-flex u-flex-direction-row-reverse u-width-100"
      >
        <metric
          size="s"
          class="u-spacing-pt-xs u-display-inline-flex"
          :config="pvpConfig"
          :data="pvpData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { formatter, getLocale } from '@/utils/helpers/formatter.js';
import Vue from 'vue';
import { commons } from '@/components/ams/campaigns/commons.js';
const metric = () => import('@/components/basic/metric');
const streamMetricLabel = () =>
  import('@/components/ams/campaigns/components/streamMetricLabel.vue');

export default {
  components: {
    streamMetricLabel,
    metric
  },
  props: {
    unit: {
      type: String,
      default: undefined
    },
    type: {
      type: String,
      default: undefined
    },
    field: {
      type: String,
      default: undefined
    },
    getter: {
      type: String,
      default: undefined
    },
    streamCell: {
      type: Boolean,
      default: false
    },
    streamGetter: {
      type: String,
      default: null
    },
    modeGetter: {
      type: String,
      default: ''
    },
    row: {
      type: Object,
      default: () => {}
    },
    enablePVPOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localValue: null
    };
  },
  computed: {
    modeSelection() {
      return this.$store?.getters?.[this.modeGetter];
    },
    streamData() {
      const value = this.stream?.data?.[this.primaryKey]?.[0]?.[this.field];
      return formatter(value, this.unit || this.type);
    },
    stream() {
      return this.$store.getters[this.streamGetter];
    },
    primaryKey() {
      return this.row?.[this.stream?.primaryKey];
    },
    pvpConfig() {
      return {
        invertTag2: commons.isInverted(this.field),
        tag2Unit: { suff: '%' }
      };
    },
    pvpData() {
      const fieldPrefix =
        this.unit?.toLowerCase() === 'percentage' ? 'PVP_PVP_DIFF' : 'PVP_PVP';
      return {
        tag2: this.row?.[`${fieldPrefix}_${this.field}`]
      };
    },
    columnData() {
      let value = this.row?.[this.field];
      return formatter(value, this.unit || this.type);
    },
    cellAlignment() {
      const rightAlignedKeys = ['numeric'];
      return rightAlignedKeys.includes(this.type.toLowerCase())
        ? 'u-flex-justify-content-flex-end'
        : 'u-flex-justify-content-flex-start';
    }
  },
  methods: {
    getToolTipText(value) {
      const localeString = Math.round(value).toLocaleString(getLocale(), {
        minimumFractionDigits: 0
      });
      let tooltipText = null;
      if (Math.round(value).toString().length > 4) {
        tooltipText = `${
          this.unit === 'CURRENCY' ? Vue.prototype.$currency : ''
        }${localeString}`;
      }
      return tooltipText;
    }
  }
};
</script>
